<template>
  <v-container fluid class="pa-6">
    <v-row no-gutters align="center" v-if="!showDataOnly"
    >
      <v-col cols="12" md="6" align="start"
      >
        <h2>
          <v-icon color="primary" large left>{{ icon }}
          </v-icon
          >
          {{ title }} <span v-if="totalCount">({{ totalCount }})</span>
        </h2>
      </v-col
      >
      <v-col cols="12" md="6" align="end">
        <v-btn
            class="primary--text"
            @click="exportFile"
        >Export
          <v-icon right color="primary">mdi-download-outline</v-icon>
        </v-btn>
        <v-btn
            v-if="userCan( [routeName] +'-create')"
            class="primary--text"
            :to="{ name: [routeName]+'-create' }"
        >Add new
          <v-icon right color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row
    >
    <v-data-iterator
        class="pt-2"
        :items="items"
        :options="pagination"
        :items-per-page.sync="pagination.rowsPerPage"
        :server-items-length.sync="totalCount"
        :page.sync="pagination.page"
        :hide-default-footer="showDataOnly"
        :loading="loading"
        :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions
      }"
    >
      <template v-slot:header v-if="items.length">
        <v-toolbar dark color="primary " class="mb-1 my-4 ">
          <v-text-field
              v-model="pagination.search"
              clearable
              flat
              solo-inverted
              hide-details
              class="mx-4"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              placeholder="Search"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-select
                v-model="pagination.orderBy"
                flat
                solo-inverted
                hide-details
                class="mx-4"
                :items="keysForSorting"
                prepend-inner-icon="mdi-sort"
                label="Sort by"
                placeholder="Sort by"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="pagination.sortDesc" mandatory>
              <v-btn large depressed color="primary" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="primary" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>
      >
      <template v-slot:no-data>
        <v-card class="pa-1" flat>
          <v-alert
              color="primary"
              elevation="2"
              type="info"
          >
            No data available
          </v-alert>
        </v-card>
      </template>
      >
      <template v-slot:loading>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
          >
            <v-toolbar-title>Loading data...</v-toolbar-title>
          </v-toolbar
          >
          <v-alert colored-border color="primary" elevation="2">
            <v-progress-circular indeterminate color="primary"/>
          </v-alert>
        </v-card>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
              v-for="item in props.items"
              :key="item.name"
              class="pa-4"
              cols="12"
              sm="6"
              md="4"
              lg="4"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card class="dekra-card" :class="{ 'on-hover': hover }">
                <v-card-title class="subheading font-weight-bold">
                  <v-row align="center">
                    <v-col cols="2" v-if="item.logo_preview">
                      <v-avatar size="56" tile class="pr-2"
                      >
                        <v-img contain :src="item.logo_preview"></v-img
                        >
                      </v-avatar>
                    </v-col>
                    <v-col
                        :cols="item.logo_preview ? 8 : 10"
                        class="text-left text-truncate"
                    >
                      <span>{{ limitSize(item[keyTitle], 22) }}</span></v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              :to="{
                              name: [routeName]+'-show',
                              params: { id: item.id }
                            }"
                              v-if="show"
                          >
                            <v-icon>mdi-eye-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>View</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item
                      v-for="(key, index) in filteredKeys"
                      :key="index"
                  >
                    <v-list-item-content
                        v-if="key"
                        :class="{
                        'primary--text': pagination.sortBy === key.value
                      }"
                    >{{ key.text }}:
                    </v-list-item-content
                    >
                    <v-list-item-content
                        v-if="key"
                        class="align-end"
                        :class="{
                        'primary--text': pagination.sortBy === key.value
                      }"
                    >
                      <span v-if="key.date">{{
                          formatDate(item[key.value])
                        }}</span>
                      <span v-else>{{
                          limitSize(getItemValue(item, key.value), 22)
                        }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-actions>
                  <div class="caption mx-2" v-if="item['created_at']">
                    {{ formatDate(item["created_at"]) }}
                  </div>
                  <div class="caption mx-2" v-if="item['date']">
                    {{ formatDate(item["date"]) }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-if="showActivityButton && userCan('settings-access-logs')"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                          activityDialog = true;
                          currentId = item.id;
                        "
                      >
                        <v-icon right>mdi-timeline-clock</v-icon>
                      </v-btn>
                    </template>
                    <span>Activity log</span>
                  </v-tooltip>
                  <v-btn
                      icon
                      v-if="showCommentsButton"
                      class="primary--text"
                  >
                    <v-icon color="primary">mdi-comment-text-multiple</v-icon>
                  </v-btn>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-if="userCan([routeName]+'-edit')"
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                          name:  [routeName]+'-edit',
                          params: { id: item.id }
                        }"
                      >
                        <v-icon right>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          class="error--text"
                          v-bind="attrs"
                          v-on="on"
                          v-if="userCan([routeName]+'-delete')"
                          @click="
                          confirmDeleteDialog = true;
                          currentId = item.id;
                        "
                      >
                        <v-icon right color="error"
                        >mdi-trash-can-outline
                        </v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="310">
      <v-card>
        <v-card-title class="error headline white--text"
        >Confirm Delete
        </v-card-title
        >
        <v-card-text class="body-1 pa-3"
        >Are you sure you want to delete this item?
        </v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              outlined
              @click.native="confirmDeleteDialog = false"
          >
            <v-icon size="20" left>mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-btn color="error" outlined @click.native="deleteItem">
            <v-icon size="20" left>mdi-trash-can-outline</v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <activity-log-dialog
        v-if="activityDialog"
        :namespace.sync="namespace"
        :color="color"
        :id.sync="currentId"
        :dialog.sync="activityDialog"
        @dialog-cancel="updateActivityDialog(false)"
        @dialog-update="updateActivityDialog"
    ></activity-log-dialog>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import permission from "@/mixins/permission";
import downloadExcel from "@/mixins/downloadExcel";
import string from "@/mixins/string";
import _ from "lodash";

export default {
  mixins: [date, permission, string, downloadExcel],
  components: {
    ActivityLogDialog: () =>
        import("@/components/core/dialogs/ActivityLogDialog")
  },
  name: "SimpleList",
  props: {
    names: {
      type: Array,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    keyTitle: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    },
    goTo: {
      type: String,
      required: false
    },
    show: {
      type: Boolean,
      default: false
    },
    showCommentsButton: {
      type: Boolean,
      default: false
    },
    showActivityButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true,
      default: "Title"
    },
    icon: {
      type: String,
      required: true,
      default: "mdi-help"
    },
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      confirmDeleteDialog: false,
      activityDialog: false,
      currentId: null,
      itemsPerPageOptions: [12, 24, 48, -1]
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters[this.namespace + "/loading"];
      }
    },
    totalCount: {
      get() {
        return this.$store.getters[this.namespace + "/total"];
      }
    },
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/getItems", false);
      }
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + "/pagination"];
      },
      set(value) {
        this.$store.dispatch(this.namespace + "/setPagination", value);
      }
    },
    color() {
      return this.$route.meta.color || "";
    },
    filteredKeys() {
      return this.names.filter(key => key.value !== this.keyTitle);
    },
    keysForSorting() {
      return this.names.filter(key => key.sortable === true);
    }
  },
  created() {
    this.getData();
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    updateActivityDialog(dialog) {
      this.activityDialog = dialog;
    },
    deleteItem() {
      const _this = this;
      _this.$store
          .dispatch(_this.namespace + "/deleteItem", this.currentId)
          .then(() => {
            this.confirmDeleteDialog = false;
            this.currentId = null;
            this.getData();
          });
    },
    async getData() {
      await this.$store.dispatch(this.namespace + "/getItems", false);
    },
    getItemValue(item, name) {
      if (name.includes(".")) {
        let nameArray = name.split(".");
        if (item[nameArray[0]] != null && !_.isEmpty(item[nameArray[0]])) {
          if (
              nameArray[2] &&
              typeof item[nameArray[0]][nameArray[1]] !== "undefined"
          ) {
            return item[nameArray[0]][nameArray[1]][nameArray[2]];
          }
          return item[nameArray[0]][nameArray[1]];
        } else {
          return "N/A";
        }
      }
      return item[name];
    },
    exportFile() {
      this.exportLoading = true;
      this.$store.dispatch(this.namespace+'/exportAllItemsGet').then(() => {
        let allItems = this.$store.getters[this.namespace+'/exportItems']
        this.exportToExcel(this.names, allItems, this.namespace + '.xlsx');
        this.exportLoading = false
      })

    }
  },

};
</script>
<style scoped>
.dekra-card {
  border-bottom: 5px solid #017d40;
  transition: transform 0.2s;
}

.dekra-card.on-hover {
  transform: scale(1.04);
}

</style>
